<template>
  <base-loading v-if="!ready" />
  <div
    v-else
    class="content"
    style="padding: 20px"
  >
    <div
      class="font-weight-bold mb-2"
      style="font-size: 24px; color: #183B94;"
    >
      Pedidos
    </div>
    <div>
      <v-carousel
        height="100%"
        hide-delimiters
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item
          v-for="(pieces, index) in slidesCard"
          :key="index"
          style="margin-left: 30px; margin-right: 30px;"
        >
          <v-row class="flex-nowrap">
            <v-col
              v-for="(item, i) in pieces"
              :key="i"
              class="d-flex justify-center"
            >
              <v-btn-toggle
                v-model="text"
                :color="statusPedidoColor(item)"
                tile
                group
                style="min-height: 140px; width: 100px; display: flex; flex-direction: column;"
              >
                <v-btn
                  :value="item"
                  elevation="1"
                  class="d-flex align-center justify-center mb-0 mt-0"
                  style="width: 97px; height: 97px; border-radius: 8px; background-color: white !important; border: 0;"
                >
                  <v-icon
                    x-large
                    :color="item === text ? 'white' : ''"
                  >
                    {{ statusPedidoIcon(item) }}
                  </v-icon>
                </v-btn>
                <div style="text-align: center; font-size: 12px; font-weight: 500; color: #696969; margin-top: 5px;">
                  {{ item }}
                </div>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </div>
    <div class="mb-14 mt-6">
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="search"
            style="background-color: white;"
            rounded
            append-icon="mdi-magnify"
            label="Pesquisar"
            outlined
            height="43"
            dense
            hide-details
            clearable
            clear-icon="mdi-close"
          />
        </v-col>
        <v-col
          cols="12"
          sm="9"
          md="4"
          class="d-flex align-center"
          style="gap: 10px"
        >
          <data-field
            v-model="data_inicial"
            :label="'Data Inicial'"
            :data-max="data_final"
            background="white"
            @set="handleDataFilter()"
          />
          <data-field
            v-model="data_final"
            :label="'Data Final'"
            background="white"
            :data-min="data_inicial"
            @set="handleDataFilter()"
          />
        </v-col>
        <v-col
          cols="12"
          sm="3"
          md="2"
          class="d-flex align-center"
        >
          <div class="font-weight-bold">
            {{ filtraPedidos(text).length }} {{ filtraPedidos(text).length > 1 ? 'Pedidos' : 'Pedido' }}
          </div>
        </v-col>
      </v-row>
      <v-data-iterator
        :items="filtraPedidos(text)"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :search="search"
        :no-data-text="'Não existem produtos para os filtros selecionados.'"
        :no-results-text="'Não foram encontrados produtos para essa pesquisa.'"
        hide-default-footer
      >
        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.id"
              cols="12"
              class="pb-0"
            >
              <v-card
                style="margin-top: 10px; border-radius: 8px;"
                class="mb-0 d-flex"
                elevation="1"
                min-height="140"
              >
                <div
                  :style="{
                    backgroundColor: statusPedidoColor(item.status),
                    width: '10px',
                    borderRadius: '8px 0px 0px 8px'
                  }"
                />
                <div style="padding: 5px 10px 5px 10px; width: 100%;">
                  <v-row @click="detalhes(`pedidos/?id=${item.id}`)">
                    <v-col
                      cols="8"
                      md="8"
                      class="d-flex justify-space-between"
                    >
                      <div
                        class="font-weight-bold"
                        :style="$vuetify.breakpoint.xs ? 'font-size: 14px; color: #183B94;' : 'font-size: 22px; color: #183B94;'"
                      >
                        Pedido #{{ item.pedido_id }}
                      </div>
                    </v-col>
                    <v-col
                      cols="4"
                      md="4"
                      class="font-weight-bold pl-0"
                      :style="$vuetify.breakpoint.xs ? 'font-size: 14px; color: #183B94; text-align: right;' : 'font-size: 22px ;color: #183B94; text-align: right;'"
                    >
                      R${{ currencyFormatter(item.valor_total) }}
                    </v-col>
                    <v-col
                      cols="12"
                      md="8"
                      :class="!$vuetify.breakpoint.smAndDown ? 'pt-0 pb-0 d-flex pt-0 align-center' : 'pt-0 pb-0 d-flex pt-0 align-center justify-space-between'"
                      style="gap: 12px; font-size: 14px;"
                    >
                      <span class="font-weight-bold">Data e Hora:</span> <span>{{ moment(item.data_pedido).format('DD/MM/YYYY HH:mm:ss') }}</span>
                    </v-col>
                    <v-col
                      v-if="$store.state.user.roles[0].slug === 'tecnico'"
                      cols="12"
                      md="8"
                      :class="!$vuetify.breakpoint.smAndDown ? 'pt-0 pb-0 d-flex pt-0 align-center' : 'pt-0 pb-0 d-flex pt-0 align-center justify-space-between'"
                      style="gap: 12px; font-size: 14px;"
                    >
                      <span class="font-weight-bold">Produtor:</span>
                      <span> {{ item.nome_produtor }} </span>
                    </v-col>
                    <v-col
                      v-if="!$vuetify.breakpoint.smAndDown"
                      cols="4"
                      :class="!$vuetify.breakpoint.smAndDown ? 'pt-0 pb-0 d-flex align-center justify-end' : 'pt-0 pb-0 d-flex flex-column justify-end align-end'"
                      style="gap: 10px"
                    >
                      <v-btn
                        small
                        style="color: white;"
                        width="110"
                        color="#2DADED"
                        class="text-none text-white"
                        @click="detalhes(`pedidos/?id=${item.id}`)"
                      >
                        Detalhes da compra
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="12"
                      md="8"
                      :class="!$vuetify.breakpoint.smAndDown ? 'pt-0 pb-0 d-flex pt-0 align-center' : 'pt-0 pb-0 d-flex pt-0 align-center justify-space-between'"
                      style="gap: 12px; font-size: 14px;"
                    >
                      <span class="font-weight-bold">Fazenda:</span>
                      <span> {{ item.nome_fazenda }} </span>
                    </v-col>
                    <v-col
                      v-if="item.resposta && item.resposta !== ''"
                      cols="12"
                      style="font-weight: 700;"
                    >
                      <v-divider />
                      <div class="mt-2">
                        Informação:
                      </div>
                      <div>
                        {{ item.resposta }}
                      </div>
                    </v-col>
                    <v-col
                      v-else-if="item.status === 'Cancelado'"
                      cols="12"
                      style="font-weight: 700;"
                    >
                      <v-divider />
                      <div class="mt-2">
                        Informação:
                      </div>
                      <div>
                        Entre em contato com o técnico de sua região
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider
                    v-if="$vuetify.breakpoint.xs"
                    class="mt-4 mb-2"
                  />
                  <v-row>
                    <v-col
                      cols="8"
                      style="height: 55px; display: flex; align-items: center;"
                    >
                      <div
                        class="font-weight-bold"
                        :style="{color: statusPedidoColor(item.status)}"
                      >
                        {{ item.status }}
                      </div>
                    </v-col>
                    <v-col
                      v-if="item.status === 'Aguardando Aceite Produtor'"
                      cols="4"
                      class="font-weight-bold pr-0 pl-0"
                      style="text-align: right;"
                    >
                      <v-btn
                        class="text-none text-white pr-0 pl-0"
                        text
                        small
                        color="success accent-4"
                      >
                        <v-icon>
                          mdi-check-bold
                        </v-icon>
                      </v-btn>
                      <v-btn
                        class="text-none text-white pr-0 pl-0"
                        text
                        small
                        color="red accent-4"
                      >
                        <v-icon color="red">
                          mdi-trash-can
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                      v-else-if="item.status === 'Aguardando Aprovação' && ($store.state.user.roles[0].slug === 'tecnico' || $store.state.user.roles[0].slug === 'gestor')"
                      cols="4"
                      class="font-weight-bold pr-0 pl-0"
                      style="text-align: right;"
                    >
                      <v-btn
                        class="text-none text-white pr-0 pl-0"
                        text
                        small
                        color="red accent-4"
                      >
                        <v-icon color="red">
                          mdi-trash-can
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                      v-else-if="item.status === 'Aguardando Aprovação' && $store.state.user.roles[0].slug === 'produtor' && checkDatePedido(item.created_at)"
                      cols="4"
                      class="font-weight-bold pr-0 pl-0"
                      style="text-align: right;"
                    >
                      <v-btn
                        class="text-none text-white pr-0 pl-0"
                        text
                        small
                        color="red accent-4"
                      >
                        <v-icon color="red">
                          mdi-trash-can
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                      v-else-if="item.status === 'Entregue à Transportadora' || item.status === 'Entregue ao Técnico'"
                      cols="4"
                      class="font-weight-bold pr-0 pl-0"
                      style="text-align: right;"
                    >
                      <v-btn
                        class="text-none text-white pr-0 pl-0"
                        text
                        small
                        color="success accent-4"
                      >
                        <v-icon>
                          mdi-truck-check
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                      v-else
                      cols="4"
                    />
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
      <div v-if="slidesCard.length === 0">
        Nao há pedidos disponíveis
      </div>
    </div>
  </div>
</template>
<script>
  import { currencyFormatter } from '../../utils/formatter'
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { statusPedidoColor, statusPedidoIcon } from '../../utils/constants'
  import PedidoStore from '@/store/modules/forms/pedido'
  import UserProfileStore from '@/store/modules/userProfile'
  import PedidosStore, { BOOTSTRAP, EDIT_PEDIDO, DELETE_PEDIDO, HISTORICO_PEDIDO } from '@/store/modules/pedidos'

  export default {
    components: {

    },
    data: () => ({
      currencyFormatter,
      statusPedidoColor,
      statusPedidoIcon,
      text: null,
      search: '',
      page: 1,
      itemsPerPage: 6,
      data_inicial: 'from',
      data_final: 'to',
    }),
    computed: {
      ...mapState('pedidos', ['ready', 'pedidos']),
      ...mapGetters(['hasPermission']),
      slidesCard () {
        const array = this.statusList
        const pieces = []
        if (this.$vuetify.breakpoint.xs) {
          const cardPerSlide = 2 // Número de cards por slide
          for (let i = 0; i < array.length; i += cardPerSlide) {
            pieces.push(array.slice(i, i + cardPerSlide))
          }
          return pieces
        } else if (this.$vuetify.breakpoint.sm) {
          const cardPerSlide = 4 // Número de cards por slide
          for (let i = 0; i < array.length; i += cardPerSlide) {
            pieces.push(array.slice(i, i + cardPerSlide))
          }
          return pieces
        } else if (this.$vuetify.breakpoint.md) {
          const cardPerSlide = 5 // Número de cards por slide
          for (let i = 0; i < array.length; i += cardPerSlide) {
            pieces.push(array.slice(i, i + cardPerSlide))
          }
          return pieces
        } else {
          const cardPerSlide = 6 // Número de cards por slide
          for (let i = 0; i < array.length; i += cardPerSlide) {
            pieces.push(array.slice(i, i + cardPerSlide))
          }
          return pieces
        }
      },
      statusList () {
        // Cria um conjunto de status únicos
        const status = new Set(this.pedidos.map(pedido => pedido.status))
        return Array.from(status) // Converte o conjunto de volta para um array
      },
    },
    created () {
      if (!this.$store.state.form.pedido) { this.$store.registerModule(['form', 'pedido'], PedidoStore) }
      if (!this.$store.hasModule('pedidos')) { this.$store.registerModule('pedidos', PedidosStore) }
      if (!this.$store.state.userProfile) { this.$store.registerModule('userProfile', UserProfileStore) }
      let data_i = new Date()
      data_i.setMonth(data_i.getMonth() - 3)
      data_i = data_i.toISOString().slice(0, 10)
      const data_f = new Date().toISOString().slice(0, 10)
      this.data_inicial = data_i
      this.data_final = data_f
      if (!this.$store.getters.hasPermission('list_pedido')) {
        this.$router.push({
          path: '/controleacesso/sempermissao',
        })
      }
      this.BOOTSTRAP({ data_inicial: data_i, data_final: data_f })
    },
    mounted () {
      this.getNextProdutos()
    },
    methods: {
      ...mapActions('pedidos', [BOOTSTRAP, EDIT_PEDIDO, DELETE_PEDIDO, HISTORICO_PEDIDO]),
      checkDatePedido (date) {
        if (date) {
          const proximoDia = new Date(date.substring(0, 10) + 'T' + date.substring(11, 19) + '.000Z')
          const data_now = new Date()

          // Avança para o próximo dia até encontrar um dia útil (segunda a sexta)
          do {
            proximoDia.setDate(proximoDia.getDate() + 1)
          } while (proximoDia.getDay() === 0 || proximoDia.getDay() === 6)

          if (data_now.toISOString().slice(0, 10) <= proximoDia.toISOString().slice(0, 10)) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
      handleDataFilter () {
        const data_i = this.data_inicial
        const data_f = this.data_final
        this.btnSelected = 'all'
        this.BOOTSTRAP({ data_inicial: data_i, data_final: data_f, tipo_pessoa: this.tipo, id: this.$store.state.user.id })
      },
      getNextProdutos () {
        window.onscroll = () => {
          const bottomOfWindow = document.documentElement.scrollHeight - (document.documentElement.scrollTop + window.innerHeight)
          if (bottomOfWindow < 1) {
            this.itemsPerPage = this.itemsPerPage + this.itemsPerPage
          }
        }
      },
      filtraPedidos (status) {
        if (status === null || status === undefined) {
          return this.pedidos
        } else {
          const pedidosFiltrados = this.pedidos.filter((e) => e.status === status)
          return pedidosFiltrados
        }
      },
      detalhes (path) {
        this.$router.push({ path }).catch(() => {})
      },
    },
  }
</script>
<style lang="scss" scoped>
.selected-btn {
  background: #183B94 !important;
  color: rgb(255, 255, 255) !important;
}

.theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before {
    opacity: 1 !important;
}
</style>
